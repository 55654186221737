import * as React from 'react';

import { Text, BlogImage, BlogLink } from '../../../components';

import { PageNew, SectionBlog } from '../../../fragments';

import therapistNevena1 from '../../../images/educational-corner/blog29/Logopedica-Nevena-i-Kokolingo-1.jpg';
import therapistNevena2 from '../../../images/educational-corner/blog29/Logopedica-Nevena-i-Kokolingo-2.jpg';
import therapistNevena3 from '../../../images/educational-corner/blog29/Logopedica-Nevena-i-Kokolingo-3.jpg';
import nevena from '../../../images/educational-corner/blog29/nevena.jpg';

const KokolingoInOsijek = () => {
  return (
    <PageNew
      title="KOKOLINGO - Kokolingo u Osijeku"
      description="Danas smo iz Primorja otišli u virtualni posjet Slavoniji 
      i donijeli vam priču iz Osijeka. Razgovarali smo s magistrom Nevenom 
      Vučinac Zelić koja radi kao logoped u KBC-u Osijek. S nama je podijelila 
      nekoliko crtica o sebi i svom radnom mjestu, o stanju u struci i radu s djecom, 
      kao i pokoju veselu anegdotu iz svakodnevne prakse."
      headline="Kokolingo u Osijeku"
    >
      <Text as="p">
        Danas smo iz Primorja otišli u virtualni posjet Slavoniji i donijeli vam
        priču iz Osijeka. Razgovarali smo s magistrom Nevenom Vučinac Zelić koja
        radi kao logoped u KBC-u Osijek. S nama je podijelila nekoliko crtica o
        sebi i svom radnom mjestu, o stanju u struci i radu s djecom, kao i
        pokoju veselu anegdotu iz svakodnevne prakse.
      </Text>
      <BlogImage
        imgAlt="Logopedica Nevena"
        imgTitle="Logopedica Nevena"
        imgSrc={nevena}
      />
      <Text as="p">
        <Text as="h4">
          Možete li nam predstaviti sebe i ustanovu u kojoj radite?
        </Text>
        <blockquote style={{ paddingLeft: '20px', margin: '1% 0 2% 0' }}>
          <cite>
            Moje ime je Nevena Vučinac Zelić, magistra sam logopedije.
            Posljednje četiri godine radim na Klinici za pedijatriju KBC-a
            Osijek. Po završetku diplomskog studija, dvije godine sam radila u
            Centru za odgoj, obrazovanje i rehabilitaciju Virovitica. Ondje sam
            stekla znanja i vještine u radu s djecom s teškoćama u razvoju.
          </cite>
        </blockquote>
      </Text>
      <Text as="p">
        <Text as="h4">
          Koliko logopeda radi u ustanovi? Uspijevaju li odgovoriti na potrebe
          za brojem stručnjaka u Vašoj regiji?
        </Text>
        <blockquote style={{ paddingLeft: '20px', margin: '1% 0 2% 0' }}>
          <cite>
            U našoj ustanovi zaposlene su još tri kolegice logopedinje koje
            svoju djelatnost obavljaju na drugim odjelima KBC-a Osijek. Osim
            naše ustanove, u gradu Osijeku postoje i druge ustanove u kojima su
            zaposleni logopedi. Svatko od nas trudi se zadovoljiti potrebe sve
            djece za logopedskom procjenom i terapijom. Kao i u većini dijelova
            Hrvatske, bilježi se manjak logopeda. Na području Slavonije i
            Baranje najveći je nedostatak logopeda u sustavu ranog predškolskog
            odgoja i obrazovanja te osnovnoškolskog sustava odgoja i
            obrazovanja.
          </cite>
        </blockquote>
      </Text>
      <Text as="p">
        <Text as="h4">Osjetite li manjak logopeda u struci?</Text>
        <blockquote style={{ paddingLeft: '20px', margin: '1% 0 2% 0' }}>
          <cite>
            S obzirom da sve više djece, ali i odraslih treba pomoć logopeda,
            pritisak je sve veći i produljuju se liste čekanja. Budući da se u
            svom radu ponajviše susrećem s djecom rane i predškolske dobi,
            ponovno bih istaknula nedostatak logopeda u vrtićima koji bi
            osigurali pravovremeno prepoznavanje i intervenciju komunikacijskih
            i jezično-govornih teškoća kod djece.
          </cite>
        </blockquote>
      </Text>
      <Text as="p">
        <Text as="h4">S koliko djece radite?</Text>
        <blockquote style={{ paddingLeft: '20px', margin: '1% 0 2% 0' }}>
          <cite>
            <Text as="p">
              U kontinuiranu logopedsku terapiju uključeno je 25-ero djece, dok
              se s brojnom drugom neurorizičnom djecom redovito provodi praćenje
              komunikacijskog i jezično-govornog razvoja od rane dobi, uz
              savjetovanje roditelja. Osim provođenja logopedske terapije i
              savjetodavnog rada, obavlja se i logopedska procjena djece od rane
              dobi do završetka osnovne škole.
            </Text>
            <Text as="p">
              Što se tiče samog rada s djecom, doznali smo da većina djece rado
              surađuje na terapijama, vole dolaziti i osjećaju se ugodno. U radu
              kod kuće djeca ponekad malo teže surađuju, ali je logopedima
              izuzetno važno motivirati ih i na tom polju, kako bi terapija bila
              učinkovita i uspješna. O suradnji s roditeljima djece koja
              pohađaju logopedske terapije magistra Vučinac Zelić podijelila je
              s nama svoje pozitivne dojmove. Suradnja s roditeljima, kaže, jako
              je važna. Uvijek je važno da roditelji budu uključeni, jer se samo
              zajedničkom suradnjom može postići napredak.
            </Text>
            <Text as="p">
              Nakon svake terapije roditeljima pokažem što smo radili i dajem
              sve materijale. Nekada im materijale šaljem i e-poštom, a ako se
              ne može poslati, pokažem im. Neki roditelji čak i kupuju
              slikovnice ili različite didaktičke materijale za rad kod kuće.
            </Text>
          </cite>
        </blockquote>
      </Text>
      <Text as="p">
        Zanimalo nas je i kako izgledaju terapije, kojim se sve rekvizitima
        logoped služi u radu te kolika je prisutnost i zastupljenost modernih
        tehnologija. Kada razgovaramo o tehnologiji, nezaobilazno je i naše
        pitanje o digitalnoj logopedskoj vježbenici{' '}
        <BlogLink>Kokolingo</BlogLink>, koja se u kabinetu magistre Vučinac
        Zelić jako puno koristi i omiljena je među djecom.
      </Text>
      <Text as="p">
        <Text as="h4">Koji su uobičajeni rekviziti jednog logopeda?</Text>
        <blockquote style={{ paddingLeft: '20px', margin: '1% 0 2% 0' }}>
          <cite>
            U provođenju logopedske terapije, koriste se različita sredstva i
            metode rada, ovisno o dobi djeteta te vrsti i težini poremećaja. U
            svom radu najčešće su to slikovni materijali i razni didaktički
            materijali, Talk Tools, potpomognuta komunikacija (komunikacijske
            knjige i ICT-AAC aplikacije).
          </cite>
        </blockquote>
      </Text>
      <BlogImage
        imgAlt="Logopedica Nevena i Kokolingo"
        imgTitle="Logopedica Nevena i Kokolingo"
        imgSrc={therapistNevena1}
      />
      <Text as="p">
        <Text as="h4">Koliko se u radu danas koristi tehnologija?</Text>
        <blockquote style={{ paddingLeft: '20px', margin: '1% 0 2% 0' }}>
          <cite>
            Unatrag nekoliko godina velika je pozornost usmjerena na
            osvještavanje i iskorištavanje prednosti
            informacijsko-komunikacijske tehnologije u logopedskoj terapiji. S
            obzirom na lakšu dostupnost i višu razinu osviještenosti o
            pozitivnim učincima tehnologije, vjerujem da se i u logopedskom radu
            sve više primjenjuje tehnologija.
          </cite>
        </blockquote>
      </Text>
      <Text as="p">
        <Text as="h4">
          Usprkos tehnologiji, postoje li aspekti u radu s djecom koji se nikada
          ne mijenjaju?
        </Text>
        <blockquote style={{ paddingLeft: '20px', margin: '1% 0 2% 0' }}>
          <cite>
            Primjena tehnologije u terapijske svrhe zasigurno pridonosi
            kvaliteti rada. Da bi se ona uspješno primjenjivala, važno je
            poznavati njezine mogućnosti te odabrati odgovarajuće sredstvo i
            pristup koji bi odgovarao pojedinom djetetu. Tehnologija može biti
            dodatna potpora, ali nikako ne može zamijeniti ili isključiti
            primjenu drugih terapijskih metoda u postizanju terapijskih ciljeva.
          </cite>
        </blockquote>
      </Text>
      <Text as="p">
        <Text as="h4">Koristite li Kokolingo?</Text>
        <blockquote style={{ paddingLeft: '20px', margin: '1% 0 2% 0' }}>
          <cite>
            U provođenju logopedske terapije s djecom s artikulacijskim i
            fonološkim poremećajima, koristim i digitalnu logopedsku vježbenicu{' '}
            <BlogLink>Kokolingo</BlogLink>.
          </cite>
        </blockquote>
      </Text>
      <BlogImage
        imgAlt="Logopedica Nevena i Kokolingo"
        imgTitle="Logopedica Nevena i Kokolingo"
        imgSrc={therapistNevena2}
      />
      <Text as="p">
        <Text as="h4">Kako Vam je pomogao u radu?</Text>
        <blockquote style={{ paddingLeft: '20px', margin: '1% 0 2% 0' }}>
          <cite>
            <BlogLink>Kokolingo</BlogLink> značajno pridonosi kvaliteti rada te
            bržem postizanju postavljenih ciljeva u terapiji artikulacijskih
            poremećaja. Djeca u Kokolingu pronalaze motivaciju, a ona je
            izrazito važna za uspješno ostvarivanje zadataka. Osim što pomaže
            djeci, ova digitalna logopedska vježbenica pridonosi i štednji glasa
            logopeda te ublažavanju vokalnog zamora nakon izvođenja
            artikulacijski zahtjevnih vježbi.
          </cite>
        </blockquote>
      </Text>
      <Text as="p">
        <Text as="h4">Kako djeca i roditelji reagiraju na ovu novinu?</Text>
        <blockquote style={{ paddingLeft: '20px', margin: '1% 0 2% 0' }}>
          <cite>
            Djeca s oduševljenjem prihvaćaju izvršavanje zadataka u tom, njima
            zabavnom „obliku“. Bolje usmjeravaju i održavaju pažnju te s ponosom
            gledaju na ono što su uspješno izvršili, što ih i dodatno motivira
            za daljnji napredak. Roditelji također pokazuju bolje razumijevanje
            problematike artikulacijskih poremećaja i svih faza korekcije
            istoga. Kokolingo pomaže i u ostvarivanju suradnje djeteta i
            roditelja kod kuće, što je nužno potrebno za postizanje bržeg
            napretka.
          </cite>
        </blockquote>
      </Text>
      <Text as="p">
        Za sam kraj razgovora ostavili smo lijepe teme o motivaciji, radu s
        djecom i entuzijazmu kojega kod naše sugovornice nimalo ne nedostaje, a
        donosimo vam i jednu zabavnu anegdotu kojih, kako kaže, nikada ne manjka
        i svaki je dan nova priča s novim veselim, mudrim, a ponekad i smiješnim
        razgovorima.
      </Text>
      <Text as="p">
        <Text as="h4">Što Vas motivira u radu s djecom?</Text>
        <blockquote style={{ paddingLeft: '20px', margin: '1% 0 2% 0' }}>
          <cite>
            <Text as="p">
              U radu s djecom motivaciju pronalazim u potrebi i želji za
              pomaganjem svakom pojedinom djetetu i njegovoj obitelji.
              Motivaciju svakako pojačava i osjećaj zadovoljstva nakon uspješno
              ostvarenih ciljeva te djetetovo iskazivanje zahvalnosti za
              dobivenu pomoć kroz crteže i pisma.
            </Text>
            <Text as="p">
              Evo i jedne anegdote. U terapiji s djecom dotaknuli smo se i
              zanimanja. Pitala sam dijete što želi biti kad odraste, a on je
              odgovorio da želi biti mljekar i odmah nadodao: „A što bi ti
              voljela biti kada odrasteš?“ To samo znači da me doživljavaju kao
              prijateljicu. Ponekad misle i da mi je ime Logopedica ili Logopeda
              (kada roditelji kažu: „Idemo kod logopeda“), a misle i da ovdje
              živim i da mi dolaze u goste.
            </Text>
          </cite>
        </blockquote>
      </Text>
      <BlogImage
        imgAlt="Logopedica Nevena i Kokolingo"
        imgTitle="Logopedica Nevena i Kokolingo"
        imgSrc={therapistNevena3}
      />
      <Text as="p">
        <Text as="h4">
          Nakon ovih veselih i lijepih priča, ostaje nam samo pitati, kada biste
          davali savjet svojim mladim kolegama koji tek započinju s radom, što
          biste im najviše željeli reći?
        </Text>
        <blockquote style={{ paddingLeft: '20px', margin: '1% 0 2% 0' }}>
          <cite>
            Važno je zadržati želju za znanjem i usavršavanjem te intrinzičnu
            motivaciju za ovaj poziv. Savjetujem mladim kolegama da ne odustaju
            u pronalaženju novih rješenja i ne posustaju ukoliko naiđu na
            prepreke bilo koje naravi. Osmijeh na licu djeteta najveća je plaća
            za sav uloženi trud.
          </cite>
        </blockquote>
      </Text>
      <SectionBlog />
    </PageNew>
  );
};

export default KokolingoInOsijek;
